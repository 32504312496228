if (document.querySelectorAll("#productSwiper").length > 0) {

    var swiperProduct = new Swiper("#productSwiper", {
        slidesPerGroup: 1,

        loop: false,
        loopFillGroupWithBlank: false,
        lazy: true,
        pagination: {
            el: "#productSwiper .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: "#productSwiper .swiper-button-next",
            prevEl: "#productSwiper .swiper-button-prev",
        },
    });
}
if (document.querySelectorAll("#mainSwiper").length > 0) {

    var swiper = new Swiper(".mainSwiper2", {
        loop: false,
        lazy: true,
        spaceBetween: 15,
        slidesPerView: 2.4,
        freeMode: true,

        watchSlidesProgress: true,
        slideActiveClass: 'swiper-slide-active',

        preventInteractionOnTransition: true,
        breakpoints: {
            576: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 5,
            },
            1200: {
                slidesPerView: 7,
            }
        },
    });
    var swiper2 = new Swiper(".mainSwiper", {
        loop: true,
        spaceBetween: 10,
        lazy: true,
        effect: "fade",
        navigation: {
            nextEl: ".mainSwiper .swiper-button-next",
            prevEl: ".mainSwiper .swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });


    swiper2.on('beforeSlideChangeStart', function (swiper) {
        if (document.querySelector("#mainSwiper .swiper-slide-active")) {
            console.log(document.querySelector("#mainSwiper .swiper-slide-active"));
        }
    });

    swiper2.on('activeIndexChange', function (swiper) {

        if (document.querySelector("#mainSwiper .swiper-slide-activeEnd")) {
            document.querySelector("#mainSwiper .swiper-slide-activeEnd").classList.remove("swiper-slide-activeEnd");
        }
        if (document.querySelector("#mainSwiper .swiper-slide-active")) {
            document.querySelector("#mainSwiper .swiper-slide-active").classList.add("swiper-slide-activeEnd");
        }
    });


}

if (document.querySelectorAll(".pe__swiperWrapper").length > 0) {
    let swiper = new Swiper(".pe__swiperWrapper .swiper", {
        slidesPerView: 1,
        spaceBetween: 20,
        lazy: true,
        navigation: {
            nextEl: ".pe__swiperWrapper .swiper-button-next",
            prevEl: ".pe__swiperWrapper .swiper-button-prev",
        },
        pagination: {
            el: ".pe__swiperWrapper .swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            1024: {
                spaceBetween: 20,
                slidesPerView: 2,
            }

        },
    });
}


if (document.querySelectorAll(".architect").length > 0) {
    let swiper = new Swiper(".architect .swiper", {
        lazy: true,
        effect: "fade",
        navigation: {
            nextEl: ".architect .swiper-button-next",
            prevEl: ".architect .swiper-button-prev",
        },
        pagination: {
            el: ".architect .swiper-pagination",
            clickable: true,
        },
        slideActiveClass: 'swiper-slide-active',
        keyboard: true,
    });
}

if (document.querySelectorAll(".ptSwiper").length > 0) {
    let swiper = new Swiper(".ptSwiper.swiper", {
        lazy: true,
        effect: "fade",
        navigation: {
            nextEl: ".ptSwiper .swiper-button-next",
            prevEl: ".ptSwiper .swiper-button-prev",
        },
        pagination: {
            el: ".ptSwiper .swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">0' + (index + 1) + "</span>";
            },
        },
        slideActiveClass: 'swiper-slide-active',
        keyboard: true,
    });
}

if (document.querySelectorAll(".listProduct_swiper").length > 0) {

    let swiper = new Swiper(".listProduct_swiper", {
        lazy: true,
        slidesPerView: 1.3,
        spaceBetween: 20,
        navigation: {
            nextEl: ".listProduct_swiper .swiper-button-next",
            prevEl: " .listProduct_swiper .swiper-button-prev",
        },

        breakpoints: {
            576: {
                slidesPerView: 2,
            },
           768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 5,
            },
        },
    });
}





